<template>
    <div class="documentNotesManage" id="ygh-content-box">
        <a-card :bordered="false" class="height100">
            <s-table ref="table" :columns="columns" :data="loadData" :alert="false" rowKey="id">
                <span slot="operator">
                    <div class="flex flex-jc-sb flex-ai-c" style="margin: 0">
                        <div>
                            <a-input-search
                                placeholder="请输入关键字"
                                @search="onSearch"
                                class="search"
                                v-model="queryParam.keyword"
                            >
                                <a-button slot="enterButton" icon="search"> </a-button>
                            </a-input-search>
                        </div>
                        <div>
                            <a-button
                                style="background: none; color: #40a9ff"
                                type="primary"
                                shape="round"
                                icon="plus"
                                @click="$refs.addNote.open()"
                                >新增版本信息</a-button
                            >
                        </div>
                    </div>
                </span>
                <template slot="createTime" slot-scope="text">
                    {{ formatDate(text) }}
                </template>
                <template slot="updateTime" slot-scope="text">
                    {{ formatDate(text) }}
                </template>
                <template slot="contentList" slot-scope="text, record">
                    <template v-if="record.contentList && record.contentList.length">
                        <a-tag color="blue" v-for="i in record.contentList" :key="i">
                            {{ i }}
                        </a-tag>
                    </template>
                </template>
            </s-table>
            <addNote ref="addNote" />
        </a-card>
    </div>
</template>

<script>
import { versionPage } from '@/api/modular/fileStream/helpCenter'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
import addNote from './components/addNote.vue'
// import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
const columns = [
    {
        title: '版本',
        dataIndex: 'title',
        ellipsis: true,
        width: 250,
    },
    {
        title: '更新记录',
        dataIndex: 'contentList',
        scopedSlots: { customRender: 'contentList' },
    },
]
export default {
    components: {
        OpenData,
        STable,
        addNote,
    },
    data() {
        return {
            columns,
            queryParam: {},
            loadData: (parameter) => {
                return versionPage({ ...parameter, ...this.queryParam })
                    .then((res) => res.data)
                    .catch(() => {
                        return new Promise((resolve, reject) => {
                            resolve({
                                pageNo: 1,
                                pageSize: 10,
                                totalPage: 0,
                                totalRows: 0,
                                rows: [],
                                rainbow: [],
                            })
                        })
                    })
            },
        }
    },
    methods: {
        onSearch() {
            this.$refs.table.refesh(true)
        },
        formatDate(text) {
            if (!text) return ''
            return moment(text).format('YYYY-MM-DD hh:mm:ss')
        },
        getTagName(text) {
            switch (text) {
                case 1:
                    return '收文登记'
                case 2:
                    return '快速发文'
                case 3:
                    return '拟稿发文'
            }
        },
    },
}
</script>

<style lang="less" scoped>
#ygh-content-box {
    height: calc(100% - 17px - 24px);
    margin: 0 auto !important;
    background: #fff;
}
</style>
